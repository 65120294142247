export const timeZones = [
    'Africa/Abidjan', 'Africa/Addis_Ababa', 'Africa/Algiers', 'Africa/Blantyre', 
    'Africa/Cairo', 'Africa/Windhoek', 'America/Adak', 'America/Anchorage', 
    'America/Argentina/Buenos_Aires', 'America/Araguaina', 'America/Belize', 
    'America/Bogota', 'America/Campo_Grande', 'America/Caracas', 'America/Chicago', 
    'America/Chihuahua', 'America/Cancun', 'America/Dawson_Creek', 'America/Denver', 
    'America/Ensenada', 'America/Glace_Bay', 'America/Godthab', 'America/Goose_Bay', 
    'America/Havana', 'America/Los_Angeles', 'America/La_Paz', 'America/Montevideo', 
    'America/Miquelon', 'America/New_York', 'America/Noronha', 'America/Santiago', 
    'America/Sao_Paulo', 'America/St_Johns', 'Atlantic/Azores', 'Atlantic/Cape_Verde', 
    'Atlantic/Stanley', 'Australia/Adelaide', 'Australia/Brisbane', 
    'Australia/Darwin', 'Australia/Eucla', 'Australia/Hobart', 'Australia/Lord_Howe', 
    'Australia/Perth', 'Europe/Amsterdam', 'Europe/Belgrade', 'Europe/Belfast', 
    'Europe/Brussels', 'Europe/Dublin', 'Europe/Lisbon', 'Europe/London', 
    'Europe/Minsk', 'Europe/Moscow', 'Pacific/Auckland', 'Pacific/Chatham', 
    'Pacific/Gambier', 'Pacific/Kiritimati', 'Pacific/Marquesas', 'Pacific/Midway', 
    'Pacific/Norfolk', 'Pacific/Tongatapu', 'Asia/Anadyr', 'Asia/Beirut', 
    'Asia/Bangkok', 'Asia/Damascus', 'Asia/Dhaka', 'Asia/Dubai', 'Asia/Gaza', 
    'Asia/Hong_Kong', 'Asia/Irkutsk', 'Asia/Jerusalem', 'Asia/Kabul', 'Asia/Katmandu', 
    'Asia/Kolkata', 'Asia/Krasnoyarsk', 'Asia/Rangoon', 'Asia/Riyadh', 
    'Asia/Seoul', 'Asia/Tashkent', 'Asia/Tehran', 'Asia/Tokyo', 'Asia/Vladivostok', 
    'Asia/Yakutsk', 'Asia/Yekaterinburg', 'Asia/Yerevan', 'Chile/EasterIsland', 
    'Etc/GMT+10', 'Etc/GMT+12', 'Etc/GMT+8', 'Etc/GMT-11', 'Etc/GMT-12',
  ];
