/* eslint-disable react/prop-types */
import React from 'react';
import './InputField.scss';

interface InputFieldProps {
  label: string;
  type?: string;
  value: string | number;
  error?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  style?: React.CSSProperties;
}

const InputField: React.FC<InputFieldProps> = ({
  label,
  type = 'text',
  value,
  error,
  onChange,
  style,
}) => (
  <div className="inputContainer" style={style}>
    <input
      type={type}
      className={`input ${error ? 'input_error' : ''}`}
      value={value}
      onChange={onChange}
      placeholder=" " // Keep placeholder blank to trigger :not(:placeholder-shown) in CSS
    />
    <label className="label">{label}</label>
    {error && <div className="error">{error}</div>}
  </div>
);

export default InputField;

