export const dateRangeOption: any = [
    'Last 7 days',
    'This Month',
    'Last Month',
    'Last Quarter',
    'Custom',
];

export const dateRangePlatformEarningOption: any = [
    'This Year',
    'Last Year',
    'Custom',
];

export const transactionFilter: any = [
    {header: 'Type', subHeader: [{key: 'Credit', value: 'credit', category: 'type'}, {key: 'Debit', value: 'debit', category: 'type'}]},
    {header: 'Status', subHeader: [{key: 'Success', value: 'payout', category: 'status'}]},
];

export const statusOption: any = [{key: 'In Review', value: 'In Review', category: 'status', color: '#FFEBFC'}, {key: 'Accepted', value: 'Accepted', category: 'status', color: '#E0FAFF'}, {key: 'Rejected', value: 'Rejected', category: 'status', color: '#ff000054'}, {key: 'Completed', value: 'Completed', category: 'status', color: '#00800061'}];

export const statusMap: any = {
    Completed: 'Completed',
    Accepted: 'Accepted',
    Rejected: 'Rejected',
};

export const dateRangeGraphOption: string[] = [
    'Today',
    'Week',
    'Month',
    'Last 30d',
    'All time',
];

export const creatorTypeOptions: {id: string, name: string}[] = [
    {"id": "7b57f3b4-a6bf-4bb1-a3e7-aa0a14b78169", "name": "Content Creator"},
    {"id": "edda6179-859a-48bd-b67c-038a803e6117", "name": "Artist"},
    {"id": "7412bd3f-0b3f-4dfb-88aa-bc3d56de0a3b", "name": "Vlogger"},
    {"id": "ca2bc725-c0a1-4436-8423-72bf64ed4f49", "name": "Gamer"},
    {"id": "da6c9c42-754e-4ee5-97d1-07dbf5f304d3", "name": "Brand"},
    {"id": "3efbe92f-6f1b-4962-884d-dcb2012cafee", "name": "Public figure"},
    {"id": "09132905-c29f-48bd-ac51-65faf1febc40", "name": "Influencer"},
    {"id": "d57f9753-7711-42b4-a937-35ca6d1133a8", "name": "Entrepreneur"},
    {"id": "01d0dccc-6ae7-4af5-96c6-8f398fd51bde", "name": "Chef"},
    {"id": "65a188b6-f7b7-4e2f-9f01-667a293aa435", "name": "Publisher"},
    {"id": "ee017f8d-5d3f-4473-a4e0-fc7985f9dd59", "name": "Other"},
];

export const contentOptions: {id: string, name: string}[] = [
    {"id": "d0bf7a3e-cbf4-4149-b09c-0d9395c14466", "name": "Business"},
    {"id": "7fa9a153-754d-4568-a941-8ab162c4b89e", "name": "Entertainment"},
    {"id": "2aef182c-c9e5-4908-9d7a-cbda1fcc1bd7", "name": "Photographer"},
    {"id": "f07a4811-abaa-4cf7-afcd-ea2f52f123b4", "name": "Music"},
    {"id": "4534bbb5-58b9-4792-82a8-58f93509812b", "name": "Sports"},
    {"id": "8e5694a9-fda7-47fe-aeee-08d8b7886ce5", "name": "Food"},
    {"id": "17de599e-3d72-4616-b022-87673d3373f6", "name": "Life style"},
    {"id": "0a5d2248-be1d-4aad-bfc7-d4069cb84bf8", "name": "Gaming"},
    {"id": "7db71698-f12d-4379-b7c3-ab0341f5a552", "name": "Family"},
    {"id": "aef652e8-37a5-4571-aed5-00d90b48f285", "name": "Story teller"},
    {"id": "38ff91a9-4f40-4017-a611-02f75dee0baf", "name": "Religious"},
    {"id": "ad32f51a-7629-41ff-9c6e-86d4c3a3b411", "name": "Tv Channel | TV Show"},
    {"id": "d6155a33-89b9-4753-a77e-54f26faccc10", "name": "Beauty and Fashion"},
    {"id": "20b77cf1-bc44-4d18-b6ed-99e94c3d8fc9", "name": "Comedy"},
    {"id": "4be615ec-320d-45c2-978f-45826b8220ff", "name": "Other"},
];

export const creatorTypeOptionsProd: {id: string, name: string}[] = [
    {"id": "fae99ec8-c7b4-4524-9b91-842471b7f682", "name": "Content Creator"},
    {"id": "3c14fc30-74b2-43f4-bb1f-283835b08d00", "name": "Artist"},
    {"id": "6423a7f9-f0cc-4017-9c29-9fad6cb50d97", "name": "Vlogger"},
    {"id": "a265329d-ef43-47c8-89fe-13b6debd506f", "name": "Gamer"},
    {"id": "e33b802d-0998-4f98-ac45-d62e0a5a035c", "name": "Brand"},
    {"id": "5ea14868-249e-4425-bebd-e3d03fce17fc", "name": "Public figure"},
    {"id": "c3363155-1878-42b2-acea-2e8e9a32a0bd", "name": "Influencer"},
    {"id": "dab7bf58-ca78-4110-bbcf-fd30efc419a3", "name": "Entrepreneur"},
    {"id": "214814f0-3a98-4353-a5b3-c2829dc12eff", "name": "Chef"},
    {"id": "520dd4fe-23ac-4df5-953b-6a32eeb58ebf", "name": "Publisher"},
    {"id": "891eadbe-e080-41e2-b810-66f0968ba941", "name": "Other"},
];

export const contentOptionsProd: {id: string, name: string}[] = [
    {"id": "4dff3264-c9fc-4159-89fc-04177c8c1a2d", "name": "Business"},
    {"id": "94d13578-9262-4163-ab8e-3b734669f39f", "name": "Entertainment"},
    {"id": "e672e60f-1abc-48fc-aa69-8e7fb604b564", "name": "Photographer"},
    {"id": "7d0d7410-56d5-4f91-a6d1-00a26d10dc96", "name": "Music"},
    {"id": "2ca9b716-d076-43f1-ad40-6e6c8dfa6a6e", "name": "Sports"},
    {"id": "fbbf3db0-f887-4a90-adb4-ba13ecd016b0", "name": "Food Bloggers"},
    {"id": "3eacf9b4-f9ce-4dcd-9e90-a47db7e32638", "name": "Life style"},
    {"id": "e9a2b88b-0a37-448e-817c-c69a4014f11c", "name": "Gaming"},
    {"id": "a791798c-4a5d-4959-8c04-4c47034379e3", "name": "Family"},
    {"id": "d5fe8672-3cf3-4e8e-b1ac-75867a8d2121", "name": "Story teller"},
    {"id": "2ca5b637-6a20-4231-b698-0cf5b4b78b76", "name": "Religious"},
    {"id": "49c38e97-cb9c-403d-b08d-d5c9e4408ad2", "name": "Tv Channel | TV Show"},
    {"id": "b6b349ac-dc30-48fc-937f-7e76ef463db0", "name": "Beauty and Fashion"},
    {"id": "1ad7cded-b0ce-4707-b726-ba32391d9529", "name": "Comedy"},
    {"id": "e3d29a04-c076-4ce9-bcf1-7ccf160988c7", "name": "Other"},
];

export enum dealsConst {
    AVAIALBLE = 'Available',
    APPROVED = 'Approved',
    APPLIED = 'In Review',
}

export enum dealType {
    COUPON = 'Coupon',
    COLLECTIONS = 'Collections',
}


export const MAX_BIO_CHARACTERS = 201;

export const timeArray = ['12:00 AM', '12:15 AM', '12:30 AM', '12:45 AM', '01:00 AM', '01:15 AM', '01:30 AM', '01:45 AM', '02:00 AM', '02:15 AM', '02:30 AM', '02:45 AM', '03:00 AM', '03:15 AM', '03:30 AM', '03:45 AM', '04:00 AM', '04:15 AM', '04:30 AM', '04:45 AM', '05:00 AM', '05:15 AM', '05:30 AM', '05:45 AM', '06:00 AM', '06:15 AM', '06:30 AM', '06:45 AM', '07:00 AM', '07:15 AM', '07:30 AM', '07:45 AM', '08:00 AM', '08:15 AM', '08:30 AM', '08:45 AM', '09:00 AM', '09:15 AM', '09:30 AM', '09:45 AM', '10:00 AM', '10:15 AM', '10:30 AM', '10:45 AM', '11:00 AM', '11:15 AM', '11:30 AM', '11:45 AM', '12:00 PM', '12:15 PM', '12:30 PM', '12:45 PM', '01:00 PM', '01:15 PM', '01:30 PM', '01:45 PM', '02:00 PM', '02:15 PM', '02:30 PM', '02:45 PM', '03:00 PM', '03:15 PM', '03:30 PM', '03:45 PM', '04:00 PM', '04:15 PM', '04:30 PM', '04:45 PM', '05:00 PM', '05:15 PM', '05:30 PM', '05:45 PM', '06:00 PM', '06:15 PM', '06:30 PM', '06:45 PM', '07:00 PM', '07:15 PM', '07:30 PM', '07:45 PM', '08:00 PM', '08:15 PM', '08:30 PM', '08:45 PM', '09:00 PM', '09:15 PM', '09:30 PM', '09:45 PM', '10:00 PM', '10:15 PM', '10:30 PM', '10:45 PM', '11:00 PM', '11:15 PM', '11:30 PM', '11:45 PM'];

export enum SectionItemContentType {
    CALENDLY = 'Calendly',
    GOOGLE_CALENDAR = 'Google Calendar',
  } 
