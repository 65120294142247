import React, {ComponentProps} from 'react';
import MuiSelect from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import styles from './Select.module.scss';
import {ReactComponent as Arrow} from '../../../assets/images/keyboard_arrow_down.svg';
import {makeStyles} from '@material-ui/core';


export const useSelectStyles = makeStyles({
  select: {
    '[dir = "rtl"] &': {
      paddingRight: 0,
      paddingLeft: 24,
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
});

type Props = Omit<ComponentProps<typeof MuiSelect>, 'label'> & {
  label: string;
};
const Select = ({children, label, ...props}: Props) => {
  const selectStyles = useSelectStyles();
  return (
    <span className={styles.textInput}>
      <MuiSelect
        className={styles.select}
        disableUnderline
        IconComponent={({className: classes}) => (
          <Arrow className={`${classes} ${styles.arrow}`} />
        )}
        classes={{select: selectStyles.select}}
        {...props}
      >
        {children}
      </MuiSelect>
      <label htmlFor='' className={props.value || props.defaultValue ? styles.active : ''}>
        {label}
      </label>
    </span>
  );
};

Select.Item = MenuItem;

export default Select;
