import React, {useEffect, useState, useMemo} from "react";
import {useTranslation} from "react-i18next";
import Loader from 'react-loader-spinner';
import {Divider} from 'antd';
import styles from './BookCallSlotPicker.module.scss';
import Select from "../LinkModal/components/Select";
import {timeZones} from "../../constants/timezone";
import moment from "moment";
import nonHookRequest from "../../features/API/nonHookRequest";
import {toast} from "react-toastify";
import {numberFollowerFormat} from "../../Helper/generalHelper";
import SelectWithSearch from "../SelectWithSearch/SelectWithSearch";

type BookCallSlotPickerProps = {
    language: string;
    currencyLabel: string;
    monetizeID: string;
    monetizeObj: {[key: string]: any};
    selectionObj: {[key: string]: any};
    setSelectedObj: React.Dispatch<React.SetStateAction<any>>;
};

const BookCallSlotPicker = ({language, currencyLabel, monetizeID, monetizeObj, selectionObj, setSelectedObj}: BookCallSlotPickerProps)=> {
  const {t} = useTranslation();

  const [dateList, setDateList] = useState<string[]>([]);
  const [availableSlots, setAvailableSlots] = useState<any>({keys: [], data: {}});
  const [loading, setLoading] = useState(false);

  const fetchUserCalendarSlots = async () => {
    try {
        setLoading(true);
        const payload = {
            linksSectionItemId: monetizeID,
            timezone: selectionObj?.timezone,
            date: moment(moment(selectionObj?.activeDate, "YYYY-MM-DD").format("YYYY-MM-DD hh:mm:ss")).utc().toISOString(),
        };
        const slotsResponse = await nonHookRequest({method: 'GET', url: `/calendar-availability/public`, params: payload});
        setDateList(slotsResponse?.calendarAvailabilityPublic);
        if (slotsResponse?.calendarAvailabilityPublic?.length) {
            const keysDuration: any = [];
            monetizeObj?.amount?.map((e: any) => keysDuration.push(e?.value));
            const obj = monetizeObj?.amount?.filter((e: any) => e?.value == keysDuration[0]);
            const value: any = obj[0]?.amount;
            const displayValue: any = isNaN(value) ? 0 : value;
            setAvailableSlots({...availableSlots, keys: keysDuration, data: slotsResponse?.calendarAvailabilityPublic[0].calendarAvailabilitySlotsDay[keysDuration[0] as string]});
            setSelectedObj({...selectionObj, activeDuration: keysDuration[0], timezone: slotsResponse?.timezone, activeDate: slotsResponse?.calendarAvailabilityPublic[0].date, amount: displayValue});
        }
        setLoading(false);
      } catch (error: any) {
        toast(<span style={{color: 'black'}}>{error?.message || 'Something went wrong!'}</span>, {autoClose: 3000});
      }
  };

  const handleSelectionChange = (value: any) => {
    setSelectedObj({...selectionObj, ...value});
  };

  const handleDurationChange = (slot: string) => {
    const activeDateData: any = dateList?.filter((e: any) => e?.date == selectionObj?.activeDate);
    if (activeDateData?.length) {
        setAvailableSlots({...availableSlots, data: activeDateData[0].calendarAvailabilitySlotsDay[slot as string]});
        setSelectedObj({...selectionObj, activeDuration: slot, activeSlot: ''});
    }
  };

  const handleDateChange = (date: string) => {
    const activeDateData: any = dateList?.filter((e: any) => e?.date == date);
    if (activeDateData?.length) {
        setAvailableSlots({...availableSlots, data: activeDateData[0]?.calendarAvailabilitySlotsDay[selectionObj.activeDuration as string]});
        setSelectedObj({...selectionObj, activeSlot: ''});
    }
    // setActiveDate(date);
    setSelectedObj({...selectionObj, activeDate: date});
  };

  const getAmountValue = () => {
    const obj = monetizeObj?.amount?.filter((e: any) => e?.value == selectionObj?.activeDuration);
    const value: any = obj[0]?.amount;
    const displayValue: any = isNaN(value) ? 0 : value;
    setSelectedObj({...selectionObj, amount: displayValue});
  };

  const handleClevertapEvent = async (data: any) => {
    await nonHookRequest({
        url: '/finance/api/clevertap/monetization_links',
        method: 'POST',
        body: {...data, productType: 'Link'},
        isShortUrl: true,
      });
  };

  useEffect(() => {
    if (selectionObj?.activeDuration) {
        getAmountValue();
    }
  }, [selectionObj?.activeDuration, selectionObj?.activeDate, monetizeObj?.amount]);

  useEffect(() => {
    fetchUserCalendarSlots();
  }, [selectionObj?.timezone]);

  useEffect(() => {
    if (monetizeID) {
        handleClevertapEvent({link_id: monetizeID, productType: 'Link', clevertapEvent: 'calendar_view_loaded'});
    }
  }, []);

  return (
    loading ? <div className={styles.loader}> <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} /> </div> : 
      <div className={styles.calendar_container}>
        <div className={styles.row}>
            <div className={styles.title}>{t('when_should_we_meet', {lng: language || 'en'})}</div>
            <div className={styles.date_container}>
                {dateList?.length ? dateList?.map((e: any) => (
                    <div onClick={() => handleDateChange(e?.date)} className={`${styles.date_box} ${e?.date == selectionObj?.activeDate ? styles.active : ''}`} key={e?.date}>
                        <div className={styles.day}>{moment(e?.date).format("ddd")}</div>
                        <div className={styles.date}>{moment(e?.date).format("DD MMM")}</div>
                    </div>
                )) : t('no_data_found')}
            </div>
        </div>
        <div className={styles.row}>
            <div className={styles.title}>{t('duration', {lng: language || 'en'})}</div>
            <div className={styles.date_container}>
                {availableSlots?.keys?.length ? availableSlots?.keys?.map((slot: string) => (
                <div key={slot} onClick={() => handleDurationChange(slot)} className={`${styles.date_box_mins} ${slot == selectionObj?.activeDuration ? styles.active : ''}`}>
                    <div className={styles.date}>{slot} mins</div>
                </div>
                )) : t('no_data_found')}
            </div>
        </div>
        <div className={styles.row}>
            <div className={styles.title}>{t('select_time_of_day', {lng: language || 'en'})}</div>
            <div className={`${styles.date_container_time}`}>
                {availableSlots?.data?.length ? availableSlots?.data?.map((time: any) => (
                    <div onClick={() => handleSelectionChange({activeSlot: time})} key={time?.startTime} className={`${styles.date_box_time} ${time == selectionObj?.activeSlot ? styles.active : ''}`}>
                        <div className={styles.date}>{time?.startTime}</div>
                    </div>
                )) : t('no_data_found')}
            </div>
        </div>
        <div className={styles.row}>
            <div className={`${styles.date_container_time}`}>
            <SelectWithSearch value={selectionObj.timezone} onChange={(e) => setSelectedObj({...selectionObj, timezone: e.target.value})} label={t('timezone')} options={timeZones.map((e) => {
              return {display: e, value: e};
            })} />
            </div>
        </div>
        <div className={styles.row}>
            <div className={styles.total_box}>
                <div className={`${styles.flex} ${styles.total}`}>{t('total', {lng: language || 'en'})}</div>
                <Divider className={styles.divider} />
                <div className={`${styles.flex} ${styles.amount}`}>{`${currencyLabel} ${selectionObj?.amount ? numberFollowerFormat(selectionObj?.amount/100) : 0}`}</div>
            </div>     
        </div>
      </div>
  );
};

export default BookCallSlotPicker;
