import React, {ComponentProps, useMemo, useState} from 'react';
import MuiSelect from '@material-ui/core/Select';
import {FormControl, ListSubheader, MenuItem, TextField} from "@material-ui/core";
import Select from '../LinkModal/components/Select';
import {useTranslation} from 'react-i18next';
import styles from './SelectWithSearch.module.scss';

type Props = Omit<ComponentProps<typeof MuiSelect>, 'label'> & {
  label: string;
  options: {[key: string]: string}[];
};

const containsText = (text: any, searchText: any) => text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;

const SelectWithSearch = ({children, label, options, ...props}: Props) => {
const {t} = useTranslation();
const [searchText, setSearchText] = useState("");
const displayedOptions = useMemo(
  () => options.filter((option: any) => containsText(option.display, searchText)),
  [searchText],
);
  return (
    <FormControl fullWidth>
        <Select
            label={label}
            value={props.value}
          MenuProps={{autoFocus: false}}
          {...props}
          onClose={() => setSearchText("")}
        >
          {/* TextField is put into ListSubheader so that it doesn't
              act as a selectable item in the menu
              i.e. we can click the TextField without triggering any selection.*/}
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder={t('type_to_search')}
              fullWidth
              className={styles.text_field}
              onClick={(e) => {
                e.stopPropagation();
              }}
              onChange={(e) => {
                setSearchText(e.target.value);
            }}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {displayedOptions.map((option: any, i: number) => (
            <MenuItem key={i} value={option.value}>
              {option.display}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
  );
};

export default SelectWithSearch;
