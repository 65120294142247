import {Method} from 'axios';
import {envConfig} from '../EnvironmentVariables/enviromentVariables';
import api from './api';

interface IUseRequest {
    url: string;
    method: Method;
    body?: any;
    params?: any;
    session?: boolean;
    isShortUrl?: boolean;
    filterError?: boolean;
  }

const makeUrl = ({url, isShortUrl}: any) => {
  const envConfigBackend = envConfig(isShortUrl).baseUrl;
  return envConfigBackend + url;
};

const nonHookRequest = async ({url, method = 'GET', body, params, session, isShortUrl=false, filterError=false}: IUseRequest) => {
  try {
    const token = (session ? sessionStorage.getItem('token') : localStorage.getItem('token')) || sessionStorage.getItem('token');
    const config = {
      headers: {"Authorization": `Bearer ${token}`, "x-api-version": "2024-09-05"},
    };
    const requestOptions = {method, data: body, params: params};
    const {data} = await api(makeUrl({url, isShortUrl}), {...requestOptions, ...(token? config : {})});
    return data;
  } catch (error:any) {
    console.warn('Error fetching - ', error);
    return filterError ? {hasError: true, error: error?.response?.data} : false;
  }
};

export default nonHookRequest;
