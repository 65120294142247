/* eslint-disable react/prop-types */
import React from 'react';
import PhoneInput, {PhoneInputProps as OriginalPhoneInputProps} from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './PhoneNumber.scss';

interface PhoneNumberProps extends Omit<OriginalPhoneInputProps, 'inputProps'> {
  localization?: Record<string, string>; // Assuming localization is an object of strings
  inputClass?: string; // Class name for the outer div
  specialLabel?: string; // Custom label text
  country?: string; // Country code
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>; // Standard HTML input attributes
}

const PhoneNumber: React.FC<PhoneNumberProps> = ({
  localization,
  inputClass,
  specialLabel = 'Phone Number', // Default label
  country = 'ae', // Default country
  inputProps,
  ...props
}) => {
  return (
    <div className={`phoneInputContainer ${inputClass}`} id={inputProps?.id}>
      <PhoneInput
        {...props}
        inputProps={{
          ...inputProps,
          style: {
            width: '100%',
            paddingTop: '20px',
            paddingBottom: '20px',
            borderRadius: '30px',
            height: '56px',
            ...inputProps?.style, // Allow overriding styles if provided
          },
        }}
        localization={localization}
        country={country}
      />
    </div>
  );
};

export default PhoneNumber;
