import * as Decode from 'jwt-decode';
import Amplify, {Auth} from 'aws-amplify';
import {CognitoHostedUIIdentityProvider} from '@aws-amplify/auth';
import {store} from '../../app/store';
import {removeMe, setMe} from '../stores/userSlicer';
import {setConfig, removeConfig} from '../stores/configSlicer';
import {setWallet, removeWallet} from '../stores/walletSlicer';
import nonHookRequest from '../API/nonHookRequest';
import {envConfig} from '../EnvironmentVariables/enviromentVariables';
import {setKyc, removeKyc, setKycUploads, removeKycUploads} from '../stores/kycSlicer';
import Cookies from "js-cookie";
import {setMusicLinks} from "../stores/musicAlbumsSlicer";
import {setDeals} from "../stores/dealsSlicer";
import {populateSocialData} from "../../Helper/generalHelper";
import {setSchedule} from "../stores/scheduleSlicer";
import {setGoogleCalendar} from "../stores/googleCalendarSlicer";

interface ICheckOption {
  navigateAway?: boolean;
  checkSession?: boolean;
}
// Amplify.Logger.LOG_LEVEL = "DEBUG";
const urlParams = new URLSearchParams(window.location.search);
const codeUrl = urlParams.get("code");
const service = urlParams.get('service');
const state = urlParams.get('state');
const error = urlParams.get('error');
let pathname = '';
if (window.location.pathname == '/earnings/payment-methods' && codeUrl) {
  pathname = `/earnings/payment-methods?paypal-code=${codeUrl}`;
}
if (window.location.pathname == '/profile/settings' && codeUrl && service && state) {
  pathname = `/profile/settings?calendar-code=${codeUrl}&service=${service}&calendar-state=${state}&calendar-error=${error}`;
}
if (window.location.pathname == '/profile/settings' && service && error) {
  pathname = `/profile/settings?&service=${service}&calendar-error=${error}`;
}
Amplify.configure({
  Auth: {
    userPoolId: envConfig().userPoolId,
    region: 'eu-west-1',
    userPoolWebClientId: envConfig().userPoolWebClientId,
    authenticationFlowType: 'USER_PASSWORD_AUTH',
    oauth: {
      domain: envConfig().cognitoOauthDomain,
      scope: ['openid', 'aws.cognito.signin.user.admin'],
      redirectSignIn: `${envConfig().cognitoOauthSignin}${pathname}`,
      redirectSignOut: envConfig().cognitoOauthSignout,
      responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
    },
  }});

const checkLogin = async (options?: ICheckOption) => {
  let navigateAway; let checkSession;
  if (options) {
    navigateAway = options.navigateAway;
    checkSession = options.checkSession;
  }

  try {
    if (window.location.href.includes('bearerToken')) {
      const location = window.location.href.split('?bearerToken=');
      const token = location[1]
          .split('&')[0]
          .substring(0, location[1].split('&')[0].length - 1)
          .replace('#', '');

      if (checkSession) {
        window.sessionStorage.setItem('token', token);
      } else {
        window.localStorage.setItem('token', token);
      }

      if (navigateAway) window.history.pushState(null, '', '/');
      return true;
    } else if (checkSession ? sessionStorage.getItem('token') : localStorage.getItem('token')) {
      const result = await getSession(checkSession);
      return result;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
};

const getSession = async (checkSession: any, getToken?: boolean) => {
  try {
    const tokens: any = await Auth.currentSession();
    if (tokens && tokens.idToken) {
      checkSession ? window.sessionStorage.setItem('token', tokens.idToken.jwtToken) : window.localStorage.setItem('token', tokens.idToken.jwtToken);
    }
    return getToken ? tokens : true;
  } catch (error: any) {
    return false;
  }
};

const fetchMe = async () => {
  store.dispatch(setMe({me: null, loginStatus: 'FETCHING'}));
  const params = {fromAlfan: 'LINKS'};
  const request = await nonHookRequest({method: 'GET', url: '/auth/me', params});
  if (request) {
    store.dispatch(setMe({me: populateSocialData(request), loginStatus: 'LOGGED_IN'}));
  } else {
    store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
  }
};

const guestView = () => {
  store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
};

const refreshMe = async (confirmation = false) => {
  const params = {fromAlfan: 'LINKS'};
  const request = await nonHookRequest({method: 'GET', url: '/auth/me', params});
  if (request) {
    store.dispatch(setMe({me: populateSocialData(request), loginStatus: 'LOGGED_IN'}));
    if (confirmation) {
      return true;
    }
  } else {
    // clear local and session storage
    localStorage.clear();
    sessionStorage.clear();
    store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
  }
};

const logOut = () => {
  store.dispatch(removeMe());
  localStorage.clear();
  sessionStorage.clear();
  logoutCognito();
};

// used when a user has an expired or invalid token and needs to log in again
const logOutUnauthenticated = () => {
  sendLogoutEvent();
  localStorage.clear();
  sessionStorage.clear();
  Auth.signOut();
  store.dispatch(setMe({me: null, loginStatus: 'ERROR'}));
};

export interface ILogin {
  Bearer: string;
  navigateTo?: string;
}

interface IOption {
  to?: string;
  saveToSessionStorage?: boolean;
}

const logIn = async (data: ILogin, options?: IOption) => {
  let to; let saveToSessionStorage;
  if (options) {
    to = options.to;
    saveToSessionStorage = options.saveToSessionStorage;
  }

  if (data) {
   saveToSessionStorage ? sessionStorage.setItem('token', data.Bearer) : localStorage.setItem('token', data.Bearer);

   const request = await nonHookRequest({method: 'GET', url: '/auth/me', session: saveToSessionStorage});
   if (request) {
     store.dispatch(
         setMe({
           me: populateSocialData(request),
           loginStatus: 'LOGGED_IN',
           hasNickname: request.hasNickname,
         }),
     );
     //  if (to) window.location.assign(to);
     return request;
   } else {
     store.dispatch(
         setMe({me: null, loginStatus: 'ERROR', hasNickname: false}),
     );
     return null;
   }
  }
};

const decodeToken = () => {
  const token = sessionStorage.getItem('token') || localStorage.getItem('token');
  if (token) {
    return Decode.default(token);
  }
  return null;
};

const createUserSession = (data: any) => {
  localStorage.setItem('userSession', window.btoa(JSON.stringify(data)));
};

const getUserSession = () => {
  const localData: any = localStorage.getItem('userSession');
  if (localData) {
    const storage: any = JSON.parse(atob(localData));
    return storage;
  }
  return null;
};

const removeUserSession = () => {
  localStorage.removeItem('userSession');
};

const loginCognito = async (payload: any) => {
  try {
    const user = await Auth.signIn(payload.email, payload.password);
    return user;
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

const signUpCognito = async (payload: any) => {
  try {
    const attr: any = {
      email: payload.email,
    };
    if (payload.referredBy) {
      attr['custom:referredBy'] = payload.referredBy;
    }
    const userSignup = await Auth.signUp({username: payload.email, password: payload.password, attributes: attr});
    return userSignup;
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

const verifyEmailCognito = async (username: string, code: string) => {
  try {
    const emailResponse = await Auth.confirmSignUp(username, code);
    return emailResponse;
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

const resendCognitoEmail = async (username: string) => {
  try {
    const resendResponse = await Auth.resendSignUp(username);
    return resendResponse;
  } catch (error: any) {
    console.log('error resend mail', error);
  }
};

const sendLogoutEvent = () => {
  window?.ReactNativeWebView?.postMessage('Logout');
};

const logoutCognito = async () => {
  try {
    sendLogoutEvent();
    await Auth.signOut();
    window.location.href = '/';
  } catch (error: any) {
    console.log('error logout', error);
  }
};

const sendForgetPasswordCognito = async (username: string) => {
  try {
    const emailResponse = await Auth.forgotPassword(username);
    return emailResponse;
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

const submitForgetDeatilsCognito = async (code: any, password: string) => {
  try {
    const params = new URLSearchParams(window.location.href.split('?')[1]);
    const data = getUserSession() || {username: params.get('email')};
    const submitResponse = await Auth.forgotPasswordSubmit(data.username, code, password);
    return submitResponse;
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

const socialAuth = async (type: CognitoHostedUIIdentityProvider, referrer?: string) => {
  try {
    if (referrer && referrer != '') {
      Cookies.set('referrer', referrer, {expires: new Date(new Date().getTime() + 60 * 60 * 1000)});
    }
    Auth.federatedSignIn({provider: type});
  } catch (error: any) {
    return {error: error.code, message: error.message};
  }
};

const updateUserCognitoAttribute = async (attribute: {[key: string]: any}) => {
  const user = await Auth.currentAuthenticatedUser({bypassCache: true});
  await Auth.updateUserAttributes(user, attribute);
};

const fetchConfig = async (params?: any) => {
  const request = await nonHookRequest({method: 'GET', url: '/appConfig', params});
  if (request) {
    store.dispatch(setConfig(request));
  } else {
    store.dispatch(removeConfig());
  }
};

const fetchWalletStore = async (params?: any) => {
  const request = await nonHookRequest({method: 'GET', url: '/finance/api/wallet', isShortUrl: true, params});
  if (request) {
    store.dispatch(setWallet(request));
  } else {
    store.dispatch(removeWallet());
  }
};
const fetchKycStore = async (params?: any) => {
  const request = await nonHookRequest({method: 'GET', url: '/finance/api/kyc', isShortUrl: true, params});
  if (request) {
    store.dispatch(setKyc(request));
  } else {
    store.dispatch(removeKyc());
  }
};

const fetchKycUploads = async (params?: any) => {
  const request = await nonHookRequest({method: 'GET', url: '/finance/api/kyc-docs', isShortUrl: true, params});
  if (request) {
    store.dispatch(setKycUploads(request));
  } else {
    store.dispatch(removeKycUploads());
  }
};

const fetchMusicLinks = async (params?: any) => {
  const request = await nonHookRequest({method: 'GET', url: '/albums', params});
  if (request) {
    store.dispatch(setMusicLinks(request));
  } else {
    console.log('error fetching music links');
  }
};

const fetchUsersDeals = async (resultHandler: any, params?: any) => {
  try {
    const dealsResult = await nonHookRequest({method: 'GET', url: '/deals', params});
    if (dealsResult?.length) {
      store.dispatch(resultHandler(dealsResult));
    } else {
      store.dispatch(resultHandler([]));
    }
  } catch (error) {
    console.log('Error occured', error);
  }
};

const fetchUserSchedule = async () => {
  try {
    const slotResult = await nonHookRequest({method: 'GET', url: '/calendar-availability'});
    if (slotResult) {
      store.dispatch(setSchedule({id: slotResult?.id, slots: slotResult?.slots, timezone: slotResult?.timezone || ''}));
    } else {
      store.dispatch(setSchedule({}));
    }
  } catch (error) {
    console.log('Error occured', error);
  }
};

const fetchGoogleCalendarConnection = async () => {
  try {
    const calenderConnectionResponse = await nonHookRequest({method: 'GET', url: '/google-oauth/validate', params: {service: 'calendar'}});
    store.dispatch(setGoogleCalendar(calenderConnectionResponse));
  } catch (error) {
    console.log('Error occured', error);
  }
};

export {checkLogin, logIn, logOut, fetchMe, guestView, refreshMe, decodeToken, loginCognito, signUpCognito, verifyEmailCognito, resendCognitoEmail, sendForgetPasswordCognito, createUserSession, getUserSession, removeUserSession, submitForgetDeatilsCognito, socialAuth, fetchConfig, logOutUnauthenticated, getSession, fetchWalletStore, fetchKycStore, fetchKycUploads, updateUserCognitoAttribute, fetchMusicLinks, fetchUsersDeals, fetchUserSchedule, fetchGoogleCalendarConnection};
