import React, {useEffect, useState} from 'react';
import useRequest, {useUploadRequest} from '../../features/API/request';
// import logo from '../../assets/images/alfan-link-logo-design-blue.svg';
import logo from '../../assets/images/AlfanBrand.svg';
import {store} from '../../app/store';
import styles from './Onboarding.module.scss';
import {useTranslation} from 'react-i18next';
import PhoneNumber from '../../components/PhoneNumber/PhoneNumber';
import {useSelector} from 'react-redux';
import {LinksSection, resetLinksUserThunk, selectLinksUser, setLinksUser} from '../../features/stores/linksUserSlicer';
import TextFieldInput from '../../components/TextFieldInput/TextFieldInput';
import SelectGender from '../../components/SelectGender/SelectGender';
import AvatarDisplay from '../../components/AvatarDisplay/AvatarDisplay';
import {selectMe} from '../../features/stores/userSlicer';
import CountrySelect from '../../components/CountrySelect/CountrySelect';
import CreatorProfile from '../../components/CreatorProfile/CreatorProfile';
import SocialMediaLinks from '../../components/SocialMediaLinks/SocialMediaLinks';
import Loader from 'react-loader-spinner';
import {toast} from 'react-toastify';
import {refreshMe} from '../../features/Login/login';
import {dataURLtoFile} from '../../Helper/imageCropper';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Cookies from 'js-cookie';
import {populateApiDataSocial, removeStepCookie} from '../../Helper/generalHelper';
import picBg from '../../assets/images/claimpage.jpg';
import worldMapBg from '../../assets/images/worldmap.jpg';
import SocialMediaBox from '../../components/SocialMediaBox/SocialMediaBox';
import RegionSelect from '../../components/RegionSelect/RegionSelect';
import {completeSignup, sendEvent} from '../../features/util';

const Onboarding = () => {
  const [step, setStep] = useState(Cookies.get('step') || 'PHONE-INPUT');
  const [loading, setLoading] = useState(false);
  const linksUser = useSelector(selectLinksUser);
  const user = useSelector(selectMe).me;
  const {t} = useTranslation();
  const updateProfileRequest = useRequest({method: 'POST', url: '/linksUser/profile-settings'});
  const uploadAvatarRequest = useUploadRequest({method: 'POST', url: '/linksUser/user-avatar'});
  const uploadLinksRequest = useRequest({method: 'POST', url: '/linksSectionItem/section-items'});

  const handleEvent = () => {
    if (localStorage.getItem('claim_username')) {
      if (user && window?.clevertap) {
        window.clevertap.profile.push({
          'Site': {
            'Identity': user.id,
            'Email': user.email,
            'SocialLogin': user?.socialSignupProvider || undefined,
          },
        });
        window.clevertap.event.push('claim_username', {'UUID': user.email});
      }
      localStorage.removeItem('claim_username');
    }
  };

  const handleProfileEvent = (eventName: string) => {
    if (window.clevertap) {
      const data = [];
      if (linksUser?.phone && !user?.phone && eventName == 'onboard_phoneno_captured') {
        data.push('phone');
      }
      if (linksUser.avatar?.includes('base64') && !user.avatar && eventName == 'onboard_avatar') {
        data.push('profile_pic');
      }
      if (linksUser.bio && !user?.linksUser?.bio && eventName == 'onboard_personal_info') {
        data.push('bio');
      }
      if (linksUser.fullname && !user?.linksUser?.fullname && eventName == 'onboard_personal_info') {
        data.push('name');
      }
      if (linksUser?.categoryIds?.length && eventName == 'onboard_personal_info') {
        data.push('categoryIds');
      }
      if (linksUser?.contentCategoryIds?.length && eventName == 'onboard_personal_info') {
        data.push('contentCategoryIds');
      }
      if (linksUser.location && !user?.linksUser?.location && eventName == 'onboard_demographic_and_gender') {
        data.push('country');
      }
      if (linksUser.city && !user?.linksUser?.city && eventName == 'onboard_demographic_and_gender') {
        data.push('city');
      }
      if (linksUser.gender && !user?.gender && eventName == 'onboard_demographic_and_gender') {
        data.push('gender');
      }
      if (eventName == 'onboard_social_links_added') {
        data.push('onboard_social_links_added');
      }
      if (data.length) {
        window.clevertap.event.push(eventName, {'data_type': data?.length ? data.join() : undefined, 'UUID': user.email});
      }
    }
  };

  useEffect(()=>{
    handleEvent();
  }, []);

  const getBodyContent = () => {
    switch (step) {
      case 'PHONE-INPUT':
        return <>
          <div className={styles.heading}>{t('your_phone_number')}</div>
          <PhoneNumber value={linksUser?.phone} country={'ae'} onChange={(v, c: {dialCode: string}, e) => {
            const phone = v[0] == '+' ? v.replace(/ /g, '') : '+' + v.replace(/ /g, '');
            const dialCode = '+' + c?.dialCode;
            let value = '';
            if (phone.substring(0, dialCode?.length) == dialCode) {
              value = phone;
            } else {
              value = `${dialCode}${v}`;
            }
            store.dispatch(setLinksUser({...linksUser, phone: value}));
          }} />
        </>;
      case 'GENDER-COUNTRY-CITY':
        return <div className={styles.page_gender}>
          <div>
            <div className={styles.heading}>{t('gender')}</div>
            <SelectGender value={linksUser?.gender || ''} handleChange={(e: string)=>{
              store.dispatch(setLinksUser({...linksUser, gender: e}));
            }} />
          </div>
          <div>
            <div className={styles.heading}>{t('country')}</div>
            <div className={styles.component_container}>
              <CountrySelect />
            </div>
          </div>
          <div>
            <div className={styles.heading}>{t('city')}</div>
            <div className={styles.component_container}>
              <RegionSelect />
            </div>
          </div>
        </div>;
      case 'AVATAR-SELECT':
        return <>
          <div className={styles.avatar_container}>
            <div className={`${styles.welcome} ${styles.nickname}`}>{t('hey')} <label>{user?.linksUser?.nickname}!</label></div>
            <div className={styles.welcome}>{t('welcome_to_alfan')}</div>
            <AvatarDisplay onBoard={true} />
          </div>

        </>;
      case 'CREATOR-PROFILE':
        return <CreatorProfile />;
      case 'SOCIAL-ICONS':
        return <>
          <div className={`${styles.heading} ${styles.icon_title}`}>{t('add_your_socials')}</div>
          <div>
            <SocialMediaLinks />
          </div>
        </>;
      default:
        return null;
    }
  };

  const addCookie = (step: string) => {
    Cookies.set('step', step);
  };

  const handleContinue = async (removeData?: boolean) => {
    switch (step) {
      case 'PHONE-INPUT':
        try {
          setLoading(true);
          const payload = {phone: linksUser?.phone || ''};
          if (!removeData) {
            await updateProfileRequest.doRequest(payload);
            const result = await refreshMe(true);
            if (result) {
              handleProfileEvent('onboard_phoneno_captured');
              sendEvent('phone_confirm');
            }
          }
          setStep('SOCIAL-ICONS');
          addCookie('SOCIAL-ICONS');
          if (removeData) {
            store.dispatch(setLinksUser({...linksUser, phone: user?.phone || ''}));
            sendEvent('phone_skip');
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
        break;
      case 'GENDER-COUNTRY-CITY':
        try {
          setLoading(true);
          const payload = {location: linksUser?.location, gender: linksUser?.gender, city: linksUser?.city || ''};
          if (!removeData) {
            await updateProfileRequest.doRequest(payload);
            const result = await refreshMe(true);
            if (result) {
              handleProfileEvent('onboard_demographic_and_gender');
              sendEvent('demographic_confirm');
            }
          }
          setStep('AVATAR-SELECT');
          addCookie('AVATAR-SELECT');
          if (removeData) {
            store.dispatch(setLinksUser({...linksUser, location: user?.linksUser?.location || '', gender: user?.gender, city: user?.linksUser?.city || ''}));
            sendEvent('demographic_skip');
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
        break;
      case 'AVATAR-SELECT':
        try {
          setLoading(true);
          if (linksUser.avatar?.includes('base64') && !removeData) {
            const file = dataURLtoFile(linksUser.avatar, `avatar-${linksUser.id}`);
            const params = {
              file: {
                key: 'avatar',
                value: file,
                fileName: linksUser.id,
              },
            };
            await uploadAvatarRequest.doRequest(params);
            const result = await refreshMe(true);
            if (result) {
              localStorage.setItem('onboard_avatar', '1');
              // handleProfileEvent('onboard_avatar');
            }
            sendEvent('avatar_confirm');
            completeSignup();
            window.location.assign('/profile/home');
          }
          if (removeData) {
            store.dispatch(setLinksUser({...linksUser, avatar: user?.linksUser?.avatar?.url || ''}));
            removeStepCookie();
            sendEvent('avatar_skip');
            completeSignup();
            window.location.assign('/profile/home');
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
        break;
      case 'CREATOR-PROFILE':
        try {
          setLoading(true);
          const payload = {bio: linksUser?.bio, fullname: linksUser?.fullname, contentCategoryIds: linksUser?.contentCategoryIds || [], categoryIds: linksUser?.categoryIds || []};
          if (!removeData) {
            await updateProfileRequest.doRequest(payload);
            const result = await refreshMe(true);
            if (result) {
              handleProfileEvent('onboard_personal_info');
              sendEvent('type_of_content_confirm');
            }
          } else {
            store.dispatch(setLinksUser({...linksUser, bio: user?.linksUser?.bio, fullname: user?.linksUser?.fullname, contentCategoryIds: user?.userContentCategory?.map((e: {category: {id: string}}) => e?.category?.id) || [], categoryIds: user?.userCategory?.map((e: {category: {id: string}}) => e?.category?.id) || []}));
            sendEvent('type_of_content_skip');
          }
          setStep('AVATAR-SELECT');
          addCookie('AVATAR-SELECT');
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
        break;
      case 'SOCIAL-ICONS':
        try {
          setLoading(true);
          if (!removeData) {
            const populateApiData = populateApiDataSocial(linksUser);
            await uploadLinksRequest.doRequest(populateApiData?.data);
            removeStepCookie();
            const result = await refreshMe(true);
            if (result && populateApiData?.count > 0) {
              handleProfileEvent('onboard_social_links_added');
              sendEvent('social_icons_confirm');
            }
          } else {
            store.dispatch(resetLinksUserThunk());
            sendEvent('social_icons_skip');
          }
          setStep('GENDER-COUNTRY-CITY');
          addCookie('GENDER-COUNTRY-CITY');
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
        break;
      default:
        break;
    }
  };

  const handlBack = () => {
    switch (step) {
      case 'GENDER-COUNTRY-CITY':
        setStep('PHONE-INPUT');
        break;
      case 'AVATAR-SELECT':
        setStep('CREATOR-PROFILE');
        break;
      case 'CREATOR-PROFILE':
        setStep('GENDER-COUNTRY-CITY');
        break;
      case 'SOCIAL-ICONS':
        setStep('AVATAR-SELECT');
        break;
      default:
        break;
    }
  };

  const isDisable = () => {
    return loading || false;
  };

  useEffect(() => {
    if (updateProfileRequest.hasError || uploadLinksRequest.hasError) {
      toast.error(<span style={{color: 'white'}}>{updateProfileRequest?.error?.message || uploadLinksRequest?.error?.message || t('something_went_wrong')}</span>);
    }
  }, [updateProfileRequest.hasError, uploadLinksRequest.hasError]);

  return (
    <>
      {loading && <div className={styles.loader}>
        <Loader type="TailSpin" color="#EB3B5A" height={80} width={80} />
      </div>}
      <section className={styles.onboardSection}>
        <div className={`${styles.container} ${step == 'AVATAR-SELECT' ? styles.full_width : ''}`}>
          <div className={`${styles.width_inherit} ${styles.logo_container}`}>
            <a className={styles.anchor} href="/"><img src={logo} alt=""/></a>
          </div>
          <div className={`${styles.width_inherit} ${styles.main_content} ${step == 'AVATAR-SELECT' ? styles.main_content_avatar : ''}`}>
            {getBodyContent()}
            <div className={`${styles.width_inherit} ${step == 'AVATAR-SELECT' ? styles.avatar_btn : ''}`}>
              <button disabled={isDisable()} type="button" onClick={() => handleContinue()} className={styles.submit}>{t(step == 'AVATAR-SELECT' ? 'lets_start' : 'next')}</button>
            </div>
            <div className={`${styles.center}`}>
              {/* {!['PHONE-INPUT'].includes(step) && <div onClick={handlBack} className={styles.skip}>
                  <ArrowBackIcon />{t('back')}
                </div>} */}
              <div onClick={() => handleContinue(true)} className={styles.skip}>
                {t('skip_this_step')}
              </div>
            </div>
          </div>
        </div>
        {step !== 'AVATAR-SELECT' &&
      <div className={`${styles.right} ${step == 'SOCIAL-ICONS' ? styles.icon_selected : styles.no_scroll}`}>
        {step == 'SOCIAL-ICONS' ? <div>
          <div className={`${styles.width_inherit} ${styles.main_content}`}>
            <div className={styles.socialLabelContainer}>
              <div>{t('your_socials')}</div>
              <div className={styles.line}></div>
            </div>
            <div>
              <SocialMediaBox />
            </div>
          </div>
        </div> :
        <img src={step == 'GENDER-COUNTRY-CITY' ? worldMapBg : picBg} alt="" />}
      </div>}
      </section>
    </>
  );
};


Onboarding.propTypes = {
};

export default Onboarding;
